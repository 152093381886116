import { Popover, Transition } from "@headlessui/react";
import {
  LifebuoyIcon,
  PowerIcon,
  UserIcon,
  BuildingOffice2Icon,
  MapPinIcon,
  ListBulletIcon
} from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";

export default function AvatarDropdown({
  logout,
  imgUrl,
  username
}: {
  logout: () => void;
  imgUrl: string;
  username?: string;
}) {
  const { userDetail, savedProviderList } = appStore();
  const {
    setIsDrawerClose,
    setEmailDialogOpen,
    setIsList,
    isList,
    isHomePage
  } = uiUseStore();
  const navigate = useNavigate();

  const onAccountClicked = () => {
    navigate("/account");
  };

  const solutions = [
    // {
    //   name: "Profile",
    //   href: "#",
    //   icon: UserCircleIcon,
    //   onclick: onAccountClicked
    // },
    // {
    //   name: "Saved Provider",
    //   href: "#",
    //   icon: HeartIcon,
    // },
    {
      name: "Help",
      href: "##",
      icon: LifebuoyIcon
    },

    {
      name: "Logout",
      href: "##",
      icon: PowerIcon,
      onclick: logout
    }
  ];

  const topSolutions = [
    {
      name: "Account",
      href: "#",
      icon: UserIcon,
      onclick: onAccountClicked
    },
    {
      name: "Wishlist",
      href: "#",
      icon: HeartIcon,
      onclick: () => {
        setIsDrawerClose(false);
        setEmailDialogOpen(false);
      }
    },
    {
      name: "List your business",
      href: "#",
      icon: BuildingOffice2Icon,
      onclick: () => {}
    }
  ];

  if (isList) {
    topSolutions.push({
      name: "Map",
      href: "#",
      icon: MapPinIcon,
      onclick: () => {
        setIsList(false);
      }
    });
  } else {
    topSolutions.push({
      name: "List",
      href: "#",
      icon: ListBulletIcon,
      onclick: () => {
        setIsList(true);
      }
    });
  }

  return (
    <div className="">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button className={`rounded-3xl inline-flex items-center`}>
              <Avatar
                imgUrl={imgUrl}
                userName={username}
                sizeClass="h-11 w-11"
                textSize="text-lg"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 xs:right-0 xs:px-2">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    <div className=" cursor-pointer flex items-center px-2 py-1 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                      <div className="ml-2">
                        <p className="text-sm font-semibold">
                          {userDetail.firstName} {userDetail.lastName}
                        </p>
                        <p className="text-[0.7rem] font-medium text-neutral-500">
                          {userDetail.email}
                        </p>
                      </div>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700 w-full"></div>
                    {topSolutions
                      .filter((item) => {
                        if (
                          isHomePage &&
                          (item.name === "List" || item.name === "Map")
                        ) {
                          return false;
                        }
                        if (
                          item.name === "Wishlist" &&
                          (!savedProviderList || savedProviderList.length === 0)
                        ) {
                          return false;
                        }
                        return true;
                      })
                      .map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            close();
                            item.onclick();
                          }}
                          className={`${
                            item.name === "List your business"
                              ? "xs:flex sm:hidden"
                              : ""
                          }${
                            item.name === "Map" || item.name === "List"
                              ? "xs:flex xl:hidden"
                              : ""
                          } cursor-pointer flex items-center justify-start p-2 -m-3 gap-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                        >
                          <div className="flex items-center justify-start flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <item.icon aria-hidden="true" className="w-6 h-6" />
                          </div>
                          <div className="">
                            <p className="text-sm font-medium">{item.name}</p>
                          </div>
                        </div>
                      ))}
                    <div className="border-b border-neutral-200 dark:border-neutral-700 w-full"></div>
                    <SwitchDarkMode />

                    {solutions.map((item, index) => (
                      <div
                        key={index}
                        // to={item.href}
                        onClick={item.onclick}
                        className=" cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
