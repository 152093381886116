import create from "zustand";
import { Cares, CareTypes } from '../types/CareTypes';
import { ProviderDetails, Providers } from "types/ProviderDetails";
import { Filters } from "types/filterProps";
import { Tenant, TenantDetails } from "types/tenant";
import { buildDefaultTenantDetails } from "utils/tenant";
import { PaginationDetails, PaginationProps } from "types/Pagination";
import { LoginResponse } from "types/responseTypes/loginResponse";

type State = {
    careTypes: Cares[];
    loading: boolean;
    providersList: Providers[];
    filteredPaginatedList: Providers[];
    savedProviderList?: Providers[];
    originalSet?: Providers[];
    filters: Filters | null;
    tenant: Tenant;
    tenantConfig: TenantDetails;
    theme: string;
    paginationDetails: PaginationDetails;
    userDetail: LoginResponse;
    profileImage: string;
}

type Action = {
    setCareTypes: (cares: Cares[]) => void;
    setLoader: (isLoading: boolean) => void;
    setProvidersList: (providers: Providers[]) => void;
    setFilteredPaginatedList: (providers: Providers[]) => void;
    setSavedProviderList: (providers: Providers[]) => void;
    setOriginalList: (providers: Providers[]) => void;
    setFilters: (filter: Filters) => void;
    setTenant: (tenant: Tenant) => void;
    setTenantConfigs: (config: TenantDetails) => void;
    setTheme: (theme: string) => void;
    setPaginationDetails: (pagination: PaginationDetails) => void;
    setUserDetail: (detail: LoginResponse) => void;
    setProfileImage: (image: string) => void;
}

const appStore = create<State & Action>((set) => ({
    originalSet: [],
    filteredPaginatedList: [],
    savedProviderList: [],
    careTypes: [],
    loading: false,
    providersList: [],
    filters: null,
    tenant: Tenant.DRD,
    tenantConfig: buildDefaultTenantDetails(),
    theme: 'light',
    paginationDetails: {
        total: 0,
        currentPage: 0,
        totalPages: 0,
    },
    userDetail: {
        id: 0,
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        token: ''
    },
    profileImage: '',
    setProfileImage: (image) => set({ profileImage: image }),
    setTheme: (_theme) => set({ theme: _theme }),
    setTenantConfigs: (config) => set({ tenantConfig: config }),
    setTenant: (_tenant) => set({ tenant: _tenant }),
    setCareTypes: (cares) => set({ careTypes: cares }),
    setLoader: (isLoading) => set({ loading: isLoading }),
    setProvidersList: (providers) => set({ providersList: providers }),
    setFilteredPaginatedList: (providers) => set({ filteredPaginatedList: providers }),
    setSavedProviderList: (provider) => set({ savedProviderList: provider }),
    setOriginalList: (providers) => set({ originalSet: providers }),
    setFilters: (filter) => set({ filters: filter }),
    setPaginationDetails: (pagination) => set({ paginationDetails: pagination }),
    setUserDetail: (detail) => set({ userDetail: detail })
}))

export default appStore;