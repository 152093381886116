import { Tab } from "@headlessui/react";
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  Fragment,
  useEffect,
  useRef,
  useState
} from "react";
import Avatar from "shared/Avatar/Avatar";
import { Helmet } from "react-helmet";
import appStore from "store/AppStore";
import toast from "react-hot-toast";
import { Services } from "services/service";
import { formatPhoneNumber } from "utils/converter";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  EnvelopeIcon,
  PhoneIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { StatusMessages } from "constants/StatusMessages";
import ImageValidation, { ImageValidationProps } from "utils/imageValidation";

export interface AuthorPageProps {
  className?: string;
}

interface UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  profilePicture: string;
}

interface Errors {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const PageProfile: FC<AuthorPageProps> = ({ className = "" }) => {
  let [categories] = useState(["Profile", "Change password"]);

  const { userDetail, setUserDetail, loading, setLoader, setProfileImage } =
    appStore();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [formData, setFormData] = useState<UserDetails>({
    id: userDetail.id || 0,
    firstName: userDetail.firstName || "",
    lastName: userDetail.lastName || "",
    email: userDetail.email || "",
    phone: userDetail.phone || "",
    profilePicture: userDetail.profilePicture || ""
  });

  useEffect(() => {
    setFormData({
      id: userDetail.id,
      email: userDetail.email,
      firstName: userDetail.firstName,
      lastName: userDetail.lastName,
      phone: userDetail.phone,
      profilePicture: userDetail.profilePicture ?? ""
    });
  }, [userDetail]);

  const [profilePictureBlob, setProfilePictureBlob] = useState<Blob | null>(
    null
  );
  const [errors, setErrors] = useState<Errors>({
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phone") {
      setFormData({ ...formData, [name]: formatPhoneNumber(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    if (
      formData.profilePicture !== undefined &&
      userDetail.profilePicture !== undefined &&
      formData.profilePicture !== userDetail.profilePicture
    ) {
      saveProfile();
    }
  }, [formData.profilePicture]);

  const handleProfilePictureChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageValidationProps: ImageValidationProps = {
        size: 2097152,
        file: file
      };
      if (ImageValidation(imageValidationProps)) {
        // Create a Blob from the file
        const blob = new Blob([file], { type: file.type });
        setProfilePictureBlob(blob);

        // Use FileReader to read the file
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              profilePicture: reader.result as string
            }));
          }
        };
        reader.onerror = (error) => {
          console.error("FileReader error:", error);
        };
        reader.readAsDataURL(file);
      }
    }
    e.target.value = "";
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const validate = (): boolean => {
    const newErrors: Errors = {
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    };

    if (!formData.firstName.trim())
      newErrors.firstName = StatusMessages.SchemaMessage.FirstNameRequired;
    if (!formData.lastName.trim())
      newErrors.lastName = StatusMessages.SchemaMessage.LastNameRequired;
    if (!formData.email.trim())
      newErrors.email = StatusMessages.SchemaMessage.EmailRequired;
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = StatusMessages.SchemaMessage.EmailAddressValidation;
    if (!formData.phone.trim())
      newErrors.phone = StatusMessages.SchemaMessage.PhoneNumberRequired;
    else if (!/^\d{3}-?\d{3}-?\d{4}$/.test(formData.phone))
      newErrors.phone = StatusMessages.SchemaMessage.PhoneNumberValid;

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    saveProfile();
  };

  const saveProfile = async () => {
    if (validate()) {
      setLoader(true);
      try {
        const result = await Services.updateProfile(
          profilePictureBlob,
          formData.firstName,
          formData.lastName,
          formData.phone,
          formData.email
        );
        setProfileImage(result?.profilePicture ?? "");
        toast.success(StatusMessages.SuccessMessages.ProfileUpdate);
        setUserDetail(formData);
      } catch (error) {
        toast.error(StatusMessages.ErrorMessage.ProfileUpdate);
      } finally {
        setLoader(false);
      }
    }
  };

  const removeProfilePicture = async () => {
    setLoader(true);
    try {
      setProfilePictureBlob(null);
      await Services.RemoveProfilePicture(formData.email);

      setProfileImage("");
      setFormData({ ...formData, profilePicture: "" });
      let details = userDetail;
      details.profilePicture = "";
      setUserDetail(details);
      toast.success(StatusMessages.SuccessMessages.ProfilePictureUpdate);
    } catch (error) {
      toast.error(StatusMessages.ErrorMessage.ProfilePictureUpdate);
    } finally {
      setLoader(false);
    }
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <div className="relative rounded-full flex cursor-pointer group">
          <div>
            <Avatar
              hasChecked
              hasCheckedClass="w-6 h-6 -top-0.5 right-2"
              sizeClass="w-28 h-28"
              imgUrl={formData.profilePicture}
              userName={formData.firstName}
              textSize="text-5xl"
            />
            <div
              onClick={handleAvatarClick}
              className="absolute inset-0 rounded-full bg-black bg-opacity-60 hidden group-hover:flex flex-col items-center justify-center text-neutral-50 cursor-pointer"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="mt-1 text-xs">Change Image</span>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              ref={fileInputRef}
              className="hidden"
            />
          </div>
          <div
            className={`bg-white dark:bg-neutral-400 absolute rounded-xl bottom-2 right-0 p-1 shadow-lg ${
              !formData.profilePicture && "hidden"
            }`}
            onClick={removeProfilePicture}
          >
            <TrashIcon className="w-4 h-4 text-red-500" />
          </div>
        </div>

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">
            {formData.firstName} {formData.lastName}
          </h2>
          {/* <StartRating className="!text-base" /> */}
        </div>

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-neutral-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                            />
                        </svg> */}
            <PhoneIcon className="h-6 w-6 text-neutral-400" />
            <span className="text-neutral-6000 dark:text-neutral-300">
              {formatPhoneNumber(formData.phone)}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <EnvelopeIcon className="h-6 w-6 text-neutral-400" />
            <span className="text-neutral-6000 dark:text-neutral-300">
              {formData.email}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Your account information</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 ">
                  <form
                    className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6"
                    onSubmit={handleSubmit}
                  >
                    <div>
                      <label className="block text-sm font-medium text-gray-600">
                        First name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        className="w-full mt-1 p-2 border rounded-md focus:outline-none"
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                    {/* ---- */}
                    <div>
                      <label className="block text-sm font-medium text-gray-600">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        className="w-full mt-1 p-2 border rounded-md focus:outline-none"
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                    {/* ---- */}
                    <div>
                      <label className="block text-sm font-medium text-gray-600">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled
                        className="w-full mt-1 p-2 border rounded-md focus:outline-none"
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-600">
                        Phone
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Phone"
                        value={formatPhoneNumber(formData.phone)}
                        onChange={handleInputChange}
                        className="w-full mt-1 p-2 border rounded-md focus:outline-none"
                      />
                      {errors.phone && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.phone}
                        </p>
                      )}
                    </div>
                    <div className="pt-2">
                      <ButtonPrimary loading={loading}>Update</ButtonPrimary>
                    </div>
                  </form>
                </div>
                {/* <div className="flex mt-11 justify-center items-center">
                                    <ButtonSecondary>Show me more</ButtonSecondary>
                                </div> */}
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2"></div>
                <div className="flex mt-11 justify-center items-center">
                  {/* <ButtonSecondary>Show me more</ButtonSecondary> */}
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2"></div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Spot.care | Profile</title>
      </Helmet>

      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row relative">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default PageProfile;
