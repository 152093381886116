export const EndpointConstants = {
    MsAllCategories: "cares/all",
    MsProvidersDetailV2: "provider/findNearest",
    MsProviderInfo: "MsProviderInfo",
    MsProviderWithQA: "provider/qna/",
    SendEmail: "provider/email",
    SaveReport: "provider/report",
    Login: "login",
    Register: "user/create",
    Update: "user/update",
    UpdateProfile: "user/profile",
    Verify: "user/verify",
    RetryVerification: 'user/retryVerification',
    GetUserByEmail: 'user/getUserByEmail',
    CreateContactMessage: 'createContact',
    ForgotPassword: 'user/forgotPassword',
    ResetPassword: 'user/resetPassword',
    RemoveProfilePicture: 'user/removePicture',
    AddWishlist: 'wishlist',
    DeleteWishlist: 'deleteFromWishlist',
    GetWishlist: 'getWishlist'
}