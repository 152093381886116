import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "components/SectionHero/SectionHero";
import { APP_CONSTANTS } from "constants/AppConstants";
import ListingStayMapPage from "containers/PageList/ProviderListV2";
import { parseProviderResults } from "contains/makers";
import { useEffect } from "react";
import { Services } from "services/service";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import { Helmet } from "react-helmet";
import Loading from "containers/Loader/Loading";
import SearchMobile from "components/SearchMobile/SearchMobile";
import { KEYS } from "constants/KeyConstants";
import { Providers } from "types/ProviderDetails";

function PageHome() {
  const {
    setProvidersList,
    setFilteredPaginatedList,
    setOriginalList,
    setPaginationDetails,
    setSavedProviderList,
    userDetail,
    setLoader,
    loading
  } = appStore();
  const {
    setHomePageListingProviderName,
    setCareType,
    setServicesTag,
    setHeaderLocation,
    setLocationValue,
    setHeaderRadius,
    isLoggedIn
  } = uiUseStore();

  useEffect(() => {
    if (isLoggedIn) {
      LoadWishList();
    } else {
      setSavedProviderList([]);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    LoadHomeScreenList();
    setCareType("");
    setServicesTag("");
    setHeaderLocation("");
    setLocationValue("");
    setHeaderRadius("");
  }, []);

  const LoadWishList = async () => {
    const filterData = {
      radius: "20",
      lat: 40.7127753,
      lon: -74.0059728,
      careType: "Skilled Nursing",
      page: 1,
      pageSize: 1000,
      postalCode: "0"
    };
    if (isLoggedIn) {
      const customerId =
        userDetail.id && userDetail.id !== 0
          ? userDetail.id
          : parseInt(localStorage.getItem(KEYS.USERID) || "0", 10);
      let wishlistData = await Services.GetWishlist(customerId);
      let wishlist: Providers[] = wishlistData.data.map((e: any) => e.provider);
      let wishlistResult = await parseProviderResults(wishlist, filterData);
      if (wishlist && wishlist.length > 0) {
        setSavedProviderList(wishlistResult);
      }
    }
  };

  const LoadHomeScreenList = async () => {
    setLoader(true);
    let liveLocationDetails: any = await Services.GetIpLocation();
    setHomePageListingProviderName(liveLocationDetails.city);

    let { data, total } = await Services.LoadCaresForHomeScreen(
      liveLocationDetails.latitude,
      liveLocationDetails.longitude
    );
    if (!total) {
      setHomePageListingProviderName("New York");
      ({ data, total } = await Services.LoadCaresForHomeScreen(
        40.7127753,
        -74.0059728
      ));
    }
    const filterData = {
      radius: "20",
      lat: 40.7127753,
      lon: -74.0059728,
      careType: "Skilled Nursing",
      page: 1,
      pageSize: 1000,
      postalCode: "0"
    };
    setPaginationDetails({
      total: total,
      currentPage: 1,
      totalPages: Math.ceil(total / 10)
    });
    let result = await parseProviderResults(data, filterData);
    setProvidersList(result);
    setOriginalList(result);
    setFilteredPaginatedList(result.slice(0, APP_CONSTANTS.PAGINATION_LIMIT));
    setLoader(false);
  };

  return (
    <div
      className={`nc-ListingStayMapPage relative`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>Spot.care | Home</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero className="xs:p-5 sm:p-8 md:px-28 md:mt-4 lg:container lg:mb-72 lg:mt-8" />
      </div>
      {loading ? (
        <div className="h-[30vh] w-full flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className="pt-1 pb-20 lg:pb-8 2xl:pl-10 xl:pr-0 xl:max-w-none">
          <ListingStayMapPage />
        </div>
      )}
      <SearchMobile />
    </div>
  );
}

export default PageHome;
