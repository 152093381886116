import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Page } from "./types";
import Page404 from "containers/Page404/Page404";
import Page403 from "containers/Page403/Page403";
import SiteHeader from "containers/SiteHeader";
import PageHome from "containers/PageHome/PageHome";
import PageList from "containers/PageList/PageList";
import uiUseStore from "store/UIStore";
import ScrollToTop from "./ScrollToTop";
import PageLogin from "containers/PageLogin/PageLogin";
import CustomDialog from "components/Dialog/CustomDialog";
import PageProfile from "containers/PageProfile/PageProfile";
import { KEYS } from "constants/KeyConstants";
import { isValidToken } from "utils/tokenValidators";
import ReportIssueDialog from "containers/dialog/ReportIssueDialog";
import SuccessDialog from "shared/SuccessDialog/SuccessDialog";
import PageContact from "containers/PageContact/PageContact";
import { logPageView } from "utils/analytics";
import Footer from "shared/Footer/Footer";
import PageResetPassword from "containers/PageResetPassword/PageResetPassword";
import PageOTP from "containers/PageOTP/PageOTP";
import BlogPage from "components/BlogPage/BlogPage";
import TermsOfUse from "containers/PageFooterOptions/TermsOfUse";
import PrivacyPolicy from "containers/PageFooterOptions/PrivacyPolicy";
import ResetPasswordRedirect from "containers/PageResetPassword/ResetPasswordRedirect";
import appStore from "store/AppStore";

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return null;
};

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/list", exact: true, component: PageList },
  { path: "/account", exact: true, component: PageProfile },
  { path: "/contact", exact: true, component: PageContact },
  { path: "/blog", exact: true, component: BlogPage },
  { path: "/reset-password", exact: true, component: ResetPasswordRedirect },
  { path: "/terms", exact: true, component: TermsOfUse },
  { path: "/privacy", exact: true, component: PrivacyPolicy }
];

const PACRNRoutes = () => {
  //const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  const {
    setIsForceLogin,
    setIsLoggedIn,
    showLogin,
    showSignup,
    isReportDialogOpen,
    setIsReportDialogOpen,
    isReportSuccessDialogOpen,
    setIsReportSuccessDialogOpen,
    isResetPassword,
    setIsResetPassword,
    isVerificationPending,
    setIsVerificationPending,
    setIsTokenRecieved,
    isTokenRecieved,
    isLoggedIn,
    setIsDarkUi,
    isDarkUi
  } = uiUseStore();

  const { setTheme } = appStore();

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      toDark();
    } else {
      toLight();
    }
  }, [isDarkUi]);

  const toDark = () => {
    setIsDarkUi(true);
    const root = document.querySelector("html");
    if (!root) return;
    !root.classList.contains("dark") && root.classList.add("dark");
    localStorage.theme = "dark";
    setTheme("dark");
  };

  const toLight = () => {
    setIsDarkUi(false);
    const root = document.querySelector("html");
    if (!root) return;
    root.classList.remove("dark");
    localStorage.theme = "light";
    setTheme("light");
  };

  useEffect(() => {
    validateAuthentication();
  }, []);

  const validateAuthentication = () => {
    if (!isValidToken()) {
      localStorage.removeItem(KEYS.TOKEN);
      localStorage.removeItem(KEYS.ISLOGGEDIN);
    }
  };

  const CheckIsAlreadyLoggedIn = () => {
    let result = localStorage.getItem(KEYS.ISLOGGEDIN) === "true";
    if (result) setIsLoggedIn(true);
  };

  useEffect(() => {
    CheckIsAlreadyLoggedIn();
  }, []);

  const onCloseForceLogin = () => {
    setIsForceLogin(false);
  };

  const handleReportDialogClose = () => {
    setIsReportDialogOpen(false);
  };

  const handleReportSuccessDialogClose = () => {
    setIsReportSuccessDialogOpen(false);
  };

  return (
    <BrowserRouter>
      <RouteTracker />
      <ScrollToTop />
      <SiteHeader />
      <div className="flex-1 border-b border-neutral-200 dark:border-neutral-700">
        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            if (path === "/account") {
              return isLoggedIn ? (
                <Route key={path} element={<Component />} path={path} />
              ) : (
                <Route
                  key={path}
                  element={<Page403 />} // Show 403 Forbidden for unauthorized access
                  path={path}
                />
              );
            }
            return <Route key={path} element={<Component />} path={path} />;
          })}
          {isLoggedIn ? (
            <Route path="*" element={<Page404 />} />
          ) : (
            <Route path="*" element={<Page403 />} />
          )}
        </Routes>
      </div>
      <CustomDialog
        isDialogOpen={showLogin || showSignup}
        handleClose={onCloseForceLogin}
        children={<PageLogin />}
        isTransistion={false}
      />
      <CustomDialog
        isDialogOpen={isReportDialogOpen}
        handleClose={handleReportDialogClose}
        children={<ReportIssueDialog />}
      />
      <CustomDialog
        isDialogOpen={isReportSuccessDialogOpen}
        handleClose={handleReportSuccessDialogClose}
        children={
          <SuccessDialog
            img={`${process.env.PUBLIC_URL}/success.png`}
            content="Thank you for sharing your concern. Our safety team will review and take appropriate action."
            onClick={handleReportSuccessDialogClose}
            btnString="Close"
          />
        }
      />
      <CustomDialog
        isDialogOpen={isResetPassword}
        handleClose={() => setIsResetPassword(false)}
        children={<PageResetPassword />}
      />
      <CustomDialog
        isDialogOpen={isVerificationPending}
        handleClose={() => setIsVerificationPending(false)}
        children={<PageOTP />}
      />
      <CustomDialog
        isDialogOpen={isTokenRecieved}
        handleClose={() => setIsTokenRecieved(false)}
        children={<PageResetPassword />}
      />

      <Footer />
    </BrowserRouter>
  );
};

export default PACRNRoutes;
