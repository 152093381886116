import { MarkerPin } from "types/MarkerPin";
import { ProviderDetails, Providers } from "types/ProviderDetails";
import { Filters } from "types/filterProps";
import { getFormattedDate } from "utils/converter";
import { getDistanceFromLatLon } from "utils/distanceFinder";

type SortKey = keyof Providers;

export const parseProviderResults = async (providers: Providers[], filters: Filters) => {
  let allMarkers: Providers[] = [];

  allMarkers = providers.map((item: Providers, index: number) => {

    var healthInspection = Math.floor(Math.random() * 6) + 1;
    var qualityMeasure = Math.floor(Math.random() * 6) + 1;
    var staffRating = Math.floor(Math.random() * 6) + 1;
    var longStayQM = Math.floor(Math.random() * 6) + 1;
    var shortStayQM = Math.floor(Math.random() * 6) + 1;
    var isCmsAvailable = false;
    var moreInfo: string = "";
    var abusereport = "";

    // Calculate overall rating by averaging individual ratings

    var overall = Math.floor((healthInspection + qualityMeasure + staffRating + longStayQM) / 4);


    var currentDate = getFormattedDate(new Date());


    if (item.rating) {
      isCmsAvailable = true;
      healthInspection = item.rating.healthInspection;
      qualityMeasure = item.rating.qualityMeasure;
      staffRating = item.rating.staffRating;
      longStayQM = item.rating.longStayQuality;
      shortStayQM = item.rating.shortStatyQuality;
      overall = item.rating.overall;
      moreInfo = item.rating.moreinfo ?? "";
      abusereport = item.rating.abusereport ?? "";
    }



    const distance = getDistanceFromLatLon(filters.lat, filters.lon, item.locations[0].latitude, item.locations[0].longitude);

    let value: Providers = {
      id: item.id,
      code: item.code,
      name: item.name,
      phone: item.phone.split(',')[0],
      email: item.email?.split(',')[0],
      services: item.services,
      images: item.images,
      locations: item.locations,
      rating: {
        overall: overall,
        healthInspection: healthInspection,
        qualityMeasure: qualityMeasure,
        staffRating: staffRating,
        longStayQuality: longStayQM,
        LastUpdated: currentDate,
        shortStatyQuality: shortStayQM,
        abusereport: abusereport,
        moreinfo: moreInfo

      },
      totalReview: item.totalReview,
      isPreffered: item.locations[0].city.includes("Atlanta") || item.locations[0].city.includes("Marietta"),
      distanceInMiles: distance,
      isRatingsAviable: isCmsAvailable,
      section: item.section,
      isSponsored: item.isSponsored
    };

    return value;
  });


  allMarkers.sort(sortByValues("isPreffered", "desc"))
  allMarkers.sort(sortByValues("isRatingsAviable", "asc"))
  allMarkers.sort(sortByValues("section", "desc"))
  allMarkers.sort(sortByValues("images", "desc"))
  allMarkers.sort(sortByValues("isSponsored", "desc"))

  return allMarkers;

}

const sortByValues = (key: SortKey, direction: "asc" | "desc") => {
  return (a: Providers, b: Providers): number => {
    let valueA: any;
    let valueB: any;

    // Check if the key exists and get values from providerDetails
    if (a && b && key in a && key in b) {
      if (key === "isRatingsAviable") {
        valueA = valueA ? 1 : 0;
        valueB = valueB ? 1 : 0;
      }
    }

    // Handle sorting for boolean values specifically for 'IsPreffered'
    if (key === "isPreffered" || key === "isSponsored") {
      // Convert boolean to number for sorting: true (preferred) > false (not preferred)
      valueA = valueA ? 1 : 0;
      valueB = valueB ? 1 : 0;
    }

    if (key === "images") {
      valueA = a[key]?.length > 0 ? 1 : 0;
      valueB = b[key]?.length > 0 ? 1 : 0;
    } else {
      valueA = a[key];
      valueB = b[key];
    }


    if (key === "section") {
      valueA = (a[key]?.length != undefined && a[key]?.length > 0) ? 1 : 0;
      valueB = (b[key]?.length != undefined && b[key]?.length > 0) ? 1 : 0;
    }

    // Default numeric and string sorting
    if (typeof valueA === "number" && typeof valueB === "number") {
      return direction === "asc" ? valueA - valueB : valueB - valueA;
    }

    return 0;
  };
};

// export const loadAllMarkers = async (profileDetails: ProviderDetails[], filter: Filters) => {
//   let allMarkers: any[] = [];
//   if (profileDetails) {
//     allMarkers = await Promise.all(
//       profileDetails.map(async (item: ProviderDetails, index: number) => {
//         const address = `${item?.Address}, ${item?.City}, ${item?.Zip}`;

//         let res = await AddressToLatLng(address);
//         const results = res["results"];

//         const healthInspection = Math.floor(Math.random() * 6) + 1;
//         const qualityMeasure = Math.floor(Math.random() * 6) + 1;
//         const staffRating = Math.floor(Math.random() * 6) + 1;
//         const longStayQM = Math.floor(Math.random() * 6) + 1;

//         // Calculate overall rating by averaging individual ratings
//         const overall = Math.floor((healthInspection + qualityMeasure + staffRating + longStayQM) / 4);


//         const currentDate = getFormattedDate(new Date());

//         let value: MarkerPin;
//         if (results?.length > 0) {
//           const i = results[0];
//           value = {
//             id: index.toString(),
//             lat: i["geometry"]["location"]["lat"],
//             lng: i["geometry"]["location"]["lng"],
//             title: item.ProviderName,
//             providerDetails: item,
//           };
//           value.providerDetails!.CurrentProvider! = filter.careType.toString() ?? "";
//           value.providerDetails!.ratings = overall;
//           value.providerDetails!.IsPreffered = item.City.includes("Atlanta") || item.City.includes("Marietta");
//           value.providerDetails!.CMS = {
//             HealthInspection: healthInspection,
//             QualityMeasure: qualityMeasure,
//             LastUpdated: currentDate,
//             LongStayQM: longStayQM,
//             StaffRating: staffRating,
//             Overall: overall
//           }
//         } else {
//           value = {
//             id: index.toString(),
//             lat: 0,
//             lng: 0,
//             title: item.ProviderName,
//             providerDetails: item,
//           };
//           value.providerDetails!.IsPreffered = item.City.includes("Atlanta") || item.City.includes("Marietta");
//           value.providerDetails!.CurrentProvider! = filter.careType.toString() ?? "";
//           value.providerDetails!.ratings = overall;
//           value.providerDetails!.CMS = {
//             HealthInspection: healthInspection,
//             QualityMeasure: qualityMeasure,
//             LastUpdated: currentDate,
//             LongStayQM: longStayQM,
//             StaffRating: staffRating,
//             Overall: overall
//           }
//         }
//         return value
//       })
//     );
//     allMarkers = sortByImage(allMarkers);
//   }

//   return allMarkers;
// };

function sortByImage(objects: any[]) {
  return objects.sort((a, b) => {
    if (a.providerDetails.Pictures === "" && b.providerDetails.Pictures !== "") {
      return 1;
    } else if (a.providerDetails.Pictures !== "" && b.providerDetails.Pictures === "") {
      return -1;
    } else {
      return a.providerDetails.Pictures.localeCompare(b.Pictures);
    }
  });
}
